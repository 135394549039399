.zoom-image {
  position: absolute;
  left: 0rem;
  width: 100%;
  overflow: hidden;

  height: calc(80vh - 6.2rem);
  top: 6.2rem;

  @media (min-width: 58rem) {
    height: calc(80vh - 9.2rem);
    top: 9.2rem;
  }

  .ol-zoom {
    top: 20px;
    left: 20px;
  }

  .ol-rotate {
    right: 20px;
    top: 20px;
  }

  .ol-control {
    button {
      &,
      &:focus,
      &:active {
        background-color: $primary;
      }

      &:hover {
        background-color: $tertiary;
      }
    }
  }
}
