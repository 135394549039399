.footer {
  position: absolute;
  bottom: 4rem;
  right: 2rem;

  background: $primary;
  color: $background;
  font-size: 1.5rem;

  padding: 0 1rem;
}
