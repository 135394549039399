.carousel {
  width: 100%;
}
.carousel-inner {
  width: 100%;
}
.carousel-current {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}   
.carousel-list {
  display: flex;
  width: calc(100% + 1rem);
  margin: -0.5rem;
  flex-wrap: wrap;
}
.carousel-item {
  width: 20%;
  padding: 0.5rem;
  cursor: pointer;
}