.text-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.height-100 {
  height: 100%;
}

.hide-on-mobile {
  @media not screen and (min-width: 58rem) {
    display: none !important;
  }
}

i.gg-icon-ins {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border: 0.2rem solid transparent;
  box-shadow: 0 0 0 0.2rem;
  border-radius: 0.4rem;
  width: 2rem;
  height: 2rem;

  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
  }
  &::after {
    border: 0.2rem solid;
    left: 0.3rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    top: 0.3rem;
  }
  &::before {
    border-radius: 0.3rem;
    width: 0.2rem;
    height: 0.2rem;
    background: currentColor;
    right: 0.1rem;
    top: 0.1rem;
  }
}
