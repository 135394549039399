.hero {
  &.progressive-responsive-image {
    position: fixed;
    /* top: 0; */
    left: 0;
    width: 100vw;
    /* height: 100vh; */
    z-index: -1;
    background-color: $placeholder;
    object-fit: cover;
    object-position: center left;

    img {
      object-fit: cover;
      object-position: left center;
    }

    top: 0;
    height: calc(24rem + 6rem + 0.2rem);

    @media (min-width: 58rem) {
      height: calc(35vh + 9rem + 0.2rem);
    }
  }
}

main {
  width: 100%;
  overflow: hidden;
  background: $background;

  padding-top: 4rem;

  @media (min-width: 58rem) {
    padding-top: 12rem;
  }

  padding-bottom: 6rem;

  display: flex;
  flex-grow: 1;

  margin-bottom: 10rem;

  &.small-hero-image {
    flex-grow: 0;
    margin-top: 12rem;
    @media (min-width: 58rem) {
      padding-top: 6rem;
    }
    @media (min-width: 58rem) {
      margin-top: 22rem;
    }
  }

  &.regular-hero-image {
    margin-top: 24rem;

    @media (min-width: 58rem) {
      margin-top: 35vh;
    }
  }

  &.no-padding {
    padding: 0;
  }
}
