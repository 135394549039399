body {
  margin: 0;
  box-sizing: border-box;
  background: $background;
  overflow-y: scroll;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.container {
  max-width: 94rem;
}

a {
  color: $secondary;
  text-decoration: none;
}

a:focus,
a:hover {
  color: $primary;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
  text-transform: uppercase;
  font-weight: 400;
}

p {
  line-height: 2.2;
}
