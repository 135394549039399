@import '../../ui/src/styles/styles.scss';

.about-page {
  .left-column {
    max-width: 36rem !important;
  }
  .zh-name {
    line-height: 1.25;
    font-size: 2rem;
  }
  .en-name {
    line-height: 1.4;
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: $primary;
  }
  .profile {
    width: 300px;
    padding-right: 4rem;
    padding-bottom: 4rem;
    margin-top: 0.6em;
  }
  p {
    line-height: 1.6;
  }
  .link {
    font-weight: 400;
    color: $primary;
    text-decoration: underline;
  }
}

.contact-page {
  min-height: calc(100vh - 40rem);

  h1 {
    font-size: 2rem;
    font-weight: 300;
    color: $secondary;
    margin-bottom: 2.5rem;
  }

  p {
    line-height: 1.5;
    font-size: 1.5rem;
  }

  p,
  i {
    color: $quaternary;
  }

  P:first-of-type {
    margin: 0;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    color: $secondary !important;
  }

  textarea {
    height: 9.1rem;
    margin: 0;
  }

  button,
  input[type='submit'] {
    margin: 0;
  }

  .bot-field {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .ins-name {
    margin-bottom: 1rem;
  }
  .ins-icon {
    margin-bottom: 2.5rem;
    margin-left: 0.2rem;
  }
}

.series-page {
  .title {
    margin-bottom: 4.5rem !important;

    h3 {
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }

    h1 {
      font-size: 2.2rem;
    }

    p {
      margin-bottom: 0;
    }

    h1,
    p {
      line-height: 1.4;
      text-transform: none;
    }
  }
}

.works-page {
  .bottom-content {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    flex-direction: column;
    align-items: center;

    margin-top: calc(80vh - 6.2rem + 1rem);

    @media (min-width: 58rem) {
      margin-top: calc(80vh - 9.2rem + 2rem);
    }

    h3 {
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }

    h1 {
      font-size: 2.2rem;
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 0;
    }

    h1,
    p {
      line-height: 1.4;
      text-transform: none;
    }
  }
}

.publication-page {
  h3 {
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 3rem;
  }

  h1 {
    font-size: 2.2rem;
  }

  padding-top: 1.5rem;

  @media (min-width: 58rem) {
    padding-top: 3rem;
  }
}
