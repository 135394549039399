.hover-image {
  background-color: $placeholder;

  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.3;

    user-select: none;

    color: $secondary;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(43, 43, 43, 0.5);
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.4s ease;

    z-index: 1;

    @media (pointer: fine) {
      &:hover {
        opacity: 1;
      }
    }

    .title {
      font-size: 2rem;
    }
    .desc {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
  }

  .bottom-text {
    display: flex;
    align-items: flex-end;
    padding-left: 1rem;

    user-select: none;

    color: $secondary;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1;

    // https://larsenwork.com/easing-gradients/
    background: linear-gradient(
      192deg,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.046) 2%,
      hsla(0, 0%, 0%, 0.08) 6.1%,
      hsla(0, 0%, 0%, 0.108) 11.9%,
      hsla(0, 0%, 0%, 0.131) 19.3%,
      hsla(0, 0%, 0%, 0.153) 27.7%,
      hsla(0, 0%, 0%, 0.176) 36.9%,
      hsla(0, 0%, 0%, 0.205) 46.6%,
      hsla(0, 0%, 0%, 0.242) 56.4%,
      hsla(0, 0%, 0%, 0.291) 66%,
      hsla(0, 0%, 0%, 0.354) 75%,
      hsla(0, 0%, 0%, 0.435) 83.1%,
      hsla(0, 0%, 0%, 0.537) 90%,
      hsla(0, 0%, 0%, 0.663) 95.3%,
      hsla(0, 0%, 0%, 0.816) 98.8%,
      hsl(0, 0%, 0%) 100%
    );

    text-shadow: 0 1px 0 black;

    font-size: 1.4rem;
    letter-spacing: 0.2rem;

    @media (pointer: fine) {
      display: none;
    }
  }
}
