$initial: #fff;
$background: #2b2b2b;
// button, code_pre, form, link
// primary: #957B5F;
$primary: #a78f75;
// font, link, button color
$secondary: #d5d5d5;
// code block, hr background
$tertiary: #808080;
// block quote, form border
$quaternary: #909090;
// Table border
$quinary: #808080;
// custom,
$dimmer: #808080;
$error: #e21c21;
$placeholder: #eddabc;
