.years-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.years-items {
  margin-bottom: 4.5%;

  .hover-image .overlay {
    .title {
      font-size: 5rem;
      letter-spacing: 1rem;
      padding-left: 1rem;
    }

    .title {
      font-family: 'Subset-Number', sans-serif;
    }

    .desc {
      font-size: 2rem;
      padding-left: 1rem;
    }
  }

  .bottom-text {
    font-family: 'Subset-Number', sans-serif;
  }
}
