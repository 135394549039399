*,
*:after,
*:before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: $secondary;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.6;
}

blockquote {
  border-left: 0.3rem solid $quaternary;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
}

blockquote *:last-child {
  margin-bottom: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: none;

  background-color: $primary;
  border: 0.1rem solid $primary;
  border-radius: 0.4rem;
  color: $initial;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: 0.1rem;
  line-height: 3.8rem;
  padding: 0 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

button:focus,
button:hover,
input[type='button']:focus,
input[type='button']:hover,
input[type='reset']:focus,
input[type='reset']:hover,
input[type='submit']:focus,
input[type='submit']:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: $initial;
  outline: 0;
}

button[disabled],
input[type='button'][disabled],
input[type='reset'][disabled],
input[type='submit'][disabled] {
  cursor: default;
  opacity: 0.5;
}

button[disabled]:focus,
button[disabled]:hover,
input[type='button'][disabled]:focus,
input[type='button'][disabled]:hover,
input[type='reset'][disabled]:focus,
input[type='reset'][disabled]:hover,
input[type='submit'][disabled]:focus,
input[type='submit'][disabled]:hover {
  background-color: $primary;
  border-color: $primary;
}

code {
  background: $tertiary;
  border-radius: 0.4rem;
  font-size: 86%;
  margin: 0 0.2rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

pre {
  background: $tertiary;
  border-left: 0.3rem solid $primary;
  overflow-y: hidden;
}

pre > code {
  border-radius: 0;
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}

hr {
  border: 0;
  border-top: 0.1rem solid $tertiary;
  margin: 3rem 0;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='week'],
input:not([type]),
textarea,
select {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.1rem solid $quaternary;
  border-radius: 0.4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: 0.6rem 1rem 0.7rem;
  width: 100%;
}

input[type='color']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='email']:focus,
input[type='month']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='url']:focus,
input[type='week']:focus,
input:not([type]):focus,
textarea:focus,
select:focus {
  border-color: $primary;
  outline: 0;
}

select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%23d1d1d1" d="M0,0l6,8l6-8"/></svg>')
    center right no-repeat;
  padding-right: 3rem;
}

select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="%239b4dca" d="M0,0l6,8l6-8"/></svg>');
}

select[multiple] {
  background: none;
  height: auto;
}

textarea {
  min-height: 6.5rem;
}

label,
legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

a {
  color: $primary;
  text-decoration: none;
}

a:focus,
a:hover {
  color: $secondary;
}

dl,
ol,
ul {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
}

dl dl,
dl ol,
dl ul,
ol dl,
ol ol,
ol ul,
ul dl,
ul ol,
ul ul {
  font-size: 90%;
  margin: 1.5rem 0 1.5rem 3rem;
}

ol {
  list-style: decimal inside;
}

ul {
  list-style: circle inside;
}

button,
dd,
dt,
li {
  margin-bottom: 1rem;
}

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem;
}

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 2.5rem;
}

b,
strong {
  font-weight: bold;
}

p {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  letter-spacing: -0.1rem;
  margin-bottom: 2rem;
  margin-top: 0;
}

h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}

h4 {
  font-size: 2.2rem;
  letter-spacing: -0.08rem;
  line-height: 1.35;
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}

img {
  max-width: 100%;
}

.container {
  margin: 0 auto;
  max-width: 112rem;
  padding: 0 2rem;
  position: relative;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.row.row-center {
  align-items: center;
}

.row .column {
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
}

@media (min-width: 58rem) {
  .row {
    flex-direction: row;
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
  .row .column {
    margin-bottom: inherit;
    padding: 0 1rem;
  }
}
