.progressive-responsive-image {
  display: block;
  position: relative;
  width: 100%;

  overflow: hidden;

  background-size: cover;
  background-repeat: no-repeat;

  img {
    object-fit: cover;

    position: absolute;
    width: 100%;
    height: 100%;

    transition: opacity 0.4s ease;
    will-change: opacity;
  }
}
