.gallery {
  width: calc(100% + 3rem);
  padding: 0;

  display: flex;
  flex-wrap: wrap;

  margin: -1.5rem;

  & > div {
    padding: 1.5rem;
    display: flex;

    flex: 0 0 100%;

    @media (min-width: 58rem) {
      flex: 0 0 50%;
    }

    @media (min-width: 94rem) {
      flex: 0 0 33.33%;
    }

    & > a {
      width: 100%;
    }
  }
}
