.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 0.2rem solid $primary;
  background: $background;

  & > .container {
    display: flex;
    min-height: 6rem;
    align-items: center;

    text-align: right;

    @media (min-width: 58rem) {
      min-height: 9rem;
    }
    & > div {
      align-items: center;
    }
    .header-items {
      color: $dimmer;
      display: inline-block;
      margin-left: 3rem;

      &.active {
        color: $primary;
      }
    }
  }

  .logo-section {
    display: flex;
    align-items: center;
    flex: 0 1 40rem;

    .text-area {
      display: flex;
      flex: 0 0;
      flex-direction: column;
    }

    .spread-evenly {
      display: flex;
      justify-content: space-evenly;
    }

    b {
      color: $primary;
      white-space: nowrap;
      line-height: 1.3;
      font-size: 1.4rem;
      /* font-size: 1.7rem; */
      margin: 0;
    }

    img {
      object-fit: cover;
    }
  }

  .logo-container {
    & {
      width: 4rem;
      height: 4rem;
      margin-right: 2rem;
    }

    img {
      object-fit: cover;
      margin-right: 2rem;
    }
  }

  .menu-toggle {
    position: absolute;
    right: 2rem;
    top: 0;

    color: $secondary;
    height: 13.5rem;
    @media not screen and (min-width: 58rem) {
      height: 6rem;
    }
    width: 4rem;

    z-index: 5;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 58rem) {
      display: none !important;
    }
  }

  .menu-content {
    z-index: 3;
  }

  .menu-link {
    width: 100%;
    display: inline-block;
    outline: 0;
    font-size: 2rem;
    text-align: center;
    padding: 1.2rem 0;

    color: $background;

    &:hover,
    &:focus {
      color: $initial;
    }
  }

  .menu-overlay {
    background-color: $primary;
    width: 100vw;
    height: 100vh;
    overflow: hidden auto;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
  }
}
